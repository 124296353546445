/**
 * Streaming -> Background
 */

import React from 'react';
import get from 'lodash/get';
import BackgroundImage from 'gatsby-background-image';
import { isOffline } from '@utils';
import css from './Background.module.css';

const Background = ({ background }) => {
  const image = get(background, 'localFile.childImageSharp.fluid');

  if (!image) {
    return null;
  }

  return (
    <div className={css.root}>
      <BackgroundImage
        className={css.backgroundImage}
        critical={isOffline}
        fluid={image}
      />
    </div>
  )
}

export default Background;
