/**
 * Streaming
 */

import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { dataFormatter } from './helpers';
import ls from 'local-storage';
import { Background, Hero, Episodes, VideoManager } from './components';
import css from './Streaming.module.css';
import get from 'lodash/get';

class Streaming extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      data: dataFormatter(this.props),
      persisted: {},
      currentlyPlaying: null
    }

    this.scrollbarsInstance = null;
  }

  componentDidMount() {
    this.init();
  }

  init = () => {

    this.setState({
      persisted: this.getPersistedData() || {},
      ready: true,
    }, () => {
      const slugFromUrl = window.location.hash && window.location.hash.replace('#', '');
      const scrollToSlug = slugFromUrl || this.state.persisted.lastPlayed;

      setTimeout(() => {
        // Scroll to the latest watched video
        // or the video passed as a hash in the URL
        if (scrollToSlug) {
          this.scrollVideoIntoView(scrollToSlug);

          const { data: { episodes } } = this.state;
          const episode = episodes.find(ep => ep.slug === slugFromUrl);

          // Play episode from slug
          if (episode) {
            this.handlePlayClick(episode);
          }
        }
      }, 500);
    })
  }

  handlePlayClick = episode => {
    window.location.hash = episode.slug;

    this.setState({
      currentlyPlaying: episode
    });
  }

  handleCloseVideo = data => {
    const slug = get(data, 'meta.slug', null);
    window.history.pushState('', document.title, window.location.pathname);

    // Sync video data in local storage
    if (data) {
      this.handlePersistVideoData(data)
    }

    // Scroll video into view
    if (slug) {
      this.scrollVideoIntoView(slug);
    }

    // Remove currently playing episode
    this.setState({
      currentlyPlaying: null
    })
  }

  /**
  * Persistence
  * This is used to store data about videos (elapsed, duration etc.)
  * so we can access it next time the user opens the app
  */

  // Get a unique persistence key
  getPersistKey = () => {
    const { id } = this.state.data;
    return `${id}-persisted`;
  }

  // Update the persisted data in local storage
  setPersistedData = (key, data) => {
    // Update persisted data in local state
    this.setState(state => ({
      persisted: {
        ...state.persisted,
        volume: data.volume,
        lastPlayed: key,
        [key]: {
          ...get(state.persisted, key, {}),
          ...data
        }
      }
    }),

    // Persist data in local storage
    () => ls.set(this.getPersistKey(), this.state.persisted))
  }

  // Get persisted data from local storage
  getPersistedData() {
    return ls.get(this.getPersistKey());
  }

  // Persist data when closing ideo
  handlePersistVideoData = data => {
    const player = get(data, 'player', {});
    const slug = get(data, 'meta.slug');
    const { currentTime, duration, hasStarted, ended, volume } = player;

    if (!slug || !currentTime) {
      return;
    }

    this.setPersistedData(slug, {
      currentTime,
      duration,
      hasStarted,
      ended,
      volume
    });
  }

  scrollVideoIntoView = slug => {
    if (!slug) {
      return;
    }

    const el = document.getElementById(`episode-${slug}`);

    if (el && this.scrollbarsInstance) {
      this.scrollbarsInstance.scroll({
        el,
        margin: 80
      }, 500)
    }
  }

  render() {
    const { 
      currentlyPlaying,
      persisted,
      data: { 
        background,
        logo,
        back_link,
        episodes,
      }, 
    } = this.state;

    return (
      <div className={css.root}>
        <div className={css.content}>
          <Hero
            logo={logo}
            backLink={back_link}
          />
          <Episodes
            getScrollbarsInstance={instance => { this.scrollbarsInstance = instance; }}
            persisted={persisted}
            episodes={episodes}
            onPlayClick={this.handlePlayClick}
          />
        </div>
        <VideoManager
          episodes={episodes}
          show={!!currentlyPlaying}
          video={currentlyPlaying}
          onCloseVideo={this.handleCloseVideo}
          persisted={persisted}
          onPlayEpisode={this.handlePlayClick}
        />
        <Background background={background} />
      </div>
    )
  }
}

export const streamingPageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      acf {
        streaming_id
        streaming_logo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        streaming_background_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000, srcSetBreakpoints: [1200]) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        streaming_back_link {
          show
          label
          path
        }
        streaming_episodes {
          eyebrow
          slug
          title
          description
          video_path
          cover {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          captions {
            file {
              url {
                localFile {
                  publicURL
                }
              }
            }
            locale
            label
          }
        }
      }
    }
  }
`

export default Streaming;
