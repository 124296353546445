/**
 * Streaming -> Episodes
 */

import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import ScrollBar from 'react-overlayscrollbars';
import { EpisodeCard } from './components';
import css from './Episodes.module.css';
import get from 'lodash/get';

class Episodes extends Component {
  constructor(props) {
    super(props);
    this.scrollbarRef = createRef(null);
    this.fadeStartRef = createRef(null);
    this.fadeEndRef = createRef(null);
  }

  state = {
    showFadeStart: false,
    showFadeEnd: true,
  }

  componentDidMount() {
    // const instance = .osInstance();
    this.props.getScrollbarsInstance(this.scrollbarRef.current);
  }

  componentWillUnmount() {
    this.scrollbarRef.current.destroy();
  }

  onScroll = e => {
    const OFFSET = 70;
    const { showFadeStart, showFadeEnd } = this.state;
    const reachedEnd = e.target.scrollWidth - e.target.scrollLeft <= e.target.offsetWidth + OFFSET;

    // Start fade
    if (!showFadeStart && e.target.scrollLeft > OFFSET) {
      this.setState({ showFadeStart: true });
    } else if (showFadeStart && e.target.scrollLeft < OFFSET) {
      this.setState({ showFadeStart: false });
    }

    // End fade
    if (!showFadeEnd && !reachedEnd) { 
      this.setState({ showFadeEnd: true });
    } else if (showFadeEnd && reachedEnd) {
      this.setState({ showFadeEnd: false });
    }
  }

  scrollBackward = () => {
    this.scrollbarRef.current.scroll({ x : "-= 500px" }, 250);
  }

  scrollForward = () => {
    this.scrollbarRef.current.scroll({ x : "+= 500px" }, 250);
  }

  render() {
    const { episodes, onPlayClick, persisted } = this.props;
    const { showFadeStart, showFadeEnd } = this.state;

    return (
      <div className={css.root}>
        <div className={classnames(css.fade, css.start, { [css.show]: showFadeStart })} ref={this.fadeStartRef}>
          <button className={css.fade__button} onClick={this.scrollBackward}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path d="M9.8 17.3l9.6 9.8c.8.8 2 .8 2.8 0 .8-.8.8-2 0-2.8L14 15.9l8.2-8.2c.8-.8.8-2 0-2.8s-2-.8-2.8 0l-9.6 9.6c-.8.9-.8 2.1 0 2.8z" />
            </svg>
          </button>
        </div>
        <ScrollBar
          ref={this.scrollbarRef}
          className={classnames('os-theme-light', css.scrollContainer)}
          overflow-behavior={{ y: 'hidden' }}
          scrollbars={{ autoHide: 'l' }}
          callbacks={{
            onScroll: this.onScroll
          }}
        >
          <ul className={css.list}>
            {episodes.map((episode, index) => (
              <EpisodeCard
                episode={episode}
                persisted={get(persisted, episode.slug, {})}
                eyebrow={episode.eyebrow}
                key={index}
                onPlayClick={onPlayClick}
              />
            ))}
          </ul>
        </ScrollBar>
        <div className={classnames(css.fade, css.end, { [css.show]: showFadeEnd })} ref={this.fadeEndRef}>
          <button className={css.fade__button} onClick={this.scrollForward}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
              <path d="M22.2 14.3l-9.6-9.8c-.8-.8-2-.8-2.8 0s-.8 2 0 2.8l8.2 8.4-8.2 8.2c-.8.8-.8 2 0 2.8s2 .8 2.8 0l9.6-9.6c.8-.9.8-2.1 0-2.8z" />
            </svg>
          </button>
        </div>
      </div>
    )
  }
}

export default Episodes;
