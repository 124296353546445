/**
 * Streaming -> Data formatter
 */

const dataFormatter = data => {
  const { data: { wordpressPage: { acf } } } = data;

  return {
    id: acf.streaming_id,
    logo: acf.streaming_logo,
    background: acf.streaming_background_image,
    episodes: acf.streaming_episodes,
    back_link: acf.streaming_back_link,
  };
}

export default dataFormatter;
