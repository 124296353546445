/**
 * Streaming -> Episodes -> EpisodeCard
 */

import React from 'react';
import get from 'lodash/get';
import BackgroundImage from 'gatsby-background-image';
import { isOffline } from '@utils';
import css from './EpisodeCard.module.css';

const ProgressBar = ({ duration, currentTime }) => {
  if (!duration || !currentTime) {
    return null;
  }

  const percentage = currentTime / duration * 100;

  return (
    <div className={css.progressBar}>
      <div className={css.progressBar__elapsed} style={{ width: `${percentage}%` }} />
    </div>
  )
}

const EpisodeCard = ({ episode, onPlayClick, eyebrow, persisted }) => {
  const { slug, title, description, cover } = episode;
  const image = get(cover, 'localFile.childImageSharp.fluid');

  const handlePlay = () => onPlayClick(episode);
  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      handlePlay();
    }
  }

  return (
    <li className={css.root} id={`episode-${slug}`}>
      {image && (
        <div tabIndex="0" className={css.cover} role="button" onClick={handlePlay} onKeyPress={handleKeyPress}>
          <ProgressBar 
            duration={persisted.duration}
            currentTime={persisted.currentTime}
          />
          <svg className={css.cover__icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.1 52.1"><path d="M43.172 23.092l-30-20c-1.9-1.2-4.3-.6-5.5 1.2-.4.6-.6 1.3-.6 2v40c.1 2.2 1.9 3.9 4.1 3.9.7 0 1.4-.2 2-.6l30-20c1.8-1.3 2.2-3.8.9-5.6-.2-.4-.6-.7-.9-.9z"/></svg>
          <BackgroundImage
            className={css.cover__image}
            critical={isOffline}
            fluid={image}
          />
        </div>
      )}
      {eyebrow && (
        <div className={css.eyebrow}>
          {eyebrow}
        </div>
      )}
      <h3 className={css.title}>
        {title}
      </h3>
      <p className={css.description}>
        {description}
      </p>
    </li>
  )
}

export default EpisodeCard;
