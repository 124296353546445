/**
 * Streaming -> VideoManager -> PlayNextVideoNotification
 */

import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import classnames from 'classnames';
import BackgroundImage from 'gatsby-background-image';
import css from './PlayNextVideoNotification.module.css';

const SHOW_WHEN_SECONDS_LEFT = 12;

const PlayNextVideoNotification = ({ episodes, currentVideo, videoState, onPlayEpisode, onAutoplayNext }) => {
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(false);
  const [dismissed, setDismissed] = useState(false);
  const isVisible = show && !dismissed;
  
  const currentVideoIndex = episodes.findIndex(episode => episode.slug === currentVideo.slug);
  const isLastVideo = currentVideoIndex === episodes.length - 1;
  const nextEpisode = episodes[currentVideoIndex + 1];

  useEffect(() => {
    if (videoState && !dismissed) {
      const timeRemaining = videoState.duration - videoState.currentTime;

      if (typeof timeRemaining === 'number') {
        if (!show && timeRemaining < SHOW_WHEN_SECONDS_LEFT) {
          setShow(true);
          if (!isLastVideo) {
            onAutoplayNext(nextEpisode);
          }
        } else if (show && timeRemaining > SHOW_WHEN_SECONDS_LEFT) {
          setShow(false);
          onAutoplayNext(null);
        }
      }
    }
  }, [videoState, show, isLastVideo, dismissed, nextEpisode]); /* eslint-disable-line */

  /** Reset hidden notification */
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHide(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    }
  }, [hide]);

  const handleDismiss = () => {
    onAutoplayNext(null);
    setDismissed(true);
    setShow(false);
  }

  const handlePlayNext = () => {
    setHide(true); // Hides immediately
  
    if (nextEpisode) {
      setShow(false);
      onPlayEpisode(nextEpisode);
    }
  }

  if (isLastVideo) {
    return null;
  }

  return (
    <div className={classnames(css.root, { [css.show]: isVisible }, { [css.hide]: hide })}>
      <div className={css.positioner}>
        <button className={css.dismissButton} onClick={handleDismiss} aria-label="Don't autoplay next video">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M4.6 27.4c.8.8 2 .8 2.8 0l8.6-8.6 8.6 8.6c.8.8 2 .8 2.8 0s.8-2 0-2.8L18.8 16l8.6-8.6c.8-.8.8-2 0-2.8s-2-.8-2.8 0L16 13.2 7.4 4.6c-.8-.8-2-.8-2.8 0s-.8 2 0 2.8l8.6 8.6-8.6 8.6c-.8.8-.8 2 0 2.8z"/></svg>
        </button>
        <button 
          className={css.card} 
          type="button" 
          onClick={handlePlayNext}
        >
          <BackgroundImage
            className={css.card__cover}
            fluid={get(nextEpisode, 'cover.localFile.childImageSharp.fluid')}
          >
            <svg className={css.card__cover__icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.1 52.1"><path d="M43.172 23.092l-30-20c-1.9-1.2-4.3-.6-5.5 1.2-.4.6-.6 1.3-.6 2v40c.1 2.2 1.9 3.9 4.1 3.9.7 0 1.4-.2 2-.6l30-20c1.8-1.3 2.2-3.8.9-5.6-.2-.4-.6-.7-.9-.9z"/></svg>
          </BackgroundImage>
          <div className={css.card__content}>
            <div className={css.card__content__eyebrow}>
              <strong>Next episode · </strong>{nextEpisode.eyebrow}
            </div>
            <h3 className={css.card__content__title}>
              {nextEpisode.title}
            </h3>
            <p className={css.card__content__description}>
              {nextEpisode.description}
            </p>
          </div>
        </button>
      </div>
    </div>
  );
}

export default PlayNextVideoNotification;
